import ImageBanner from '@/components/ui/organisms/image-banner/image-banner'
import { Title as TitleBase } from '@/components/ui/organisms/image-banner/image-banner.styled'
import styled from '@emotion/styled'

export const ImageBannerStyled = styled(ImageBanner)`
	border: 3px solid ${({ theme }) => theme.palette.primary.main};
	border-radius: calc(var(--card-border-radius) * 2) !important;
	max-width: 160px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	.img {
		padding: ${({ theme }) => theme.spacing(1, 4)};
		width: calc(100% - ${({ theme }) => theme.spacing(8)});
		span {
			border-radius: var(--card-border-radius) !important;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: ${({ theme }) => theme.spacing(3, 5)};
			width: calc(100% - ${({ theme }) => theme.spacing(10)});
		}
	}
`

export const Title = styled(TitleBase)`
	position: initial;
	transform: none;
	color: ${({ theme }) => theme.palette.primary.main};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`
