import { cx } from '@emotion/css'
import ImageBanner from '../image-banner/image-banner'
import { ImageBannerStyled, Title } from './icon-banner.styled'

const IconBanner = ({ className, ...props }) => {
	return <ImageBannerStyled className={cx('IconBanner-root', className)} {...props} TitleComponent={Title} />
}

IconBanner.defaultProps = {
	...ImageBanner.defaultProps,
}

IconBanner.propTypes = {
	...ImageBanner.propTypes,
}

export default IconBanner
